import React from 'react';
import styled from 'styled-components';

const TitleAreaDiv = styled.div`
  z-index: 2000;
  position: absolute;
  text-shadow: 0 0 10px #000;
  top: 85px;
  box-sizing: border-box;
  margin: 0;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    top: 65px !important;
    margin-left: 8vmin;
    text-shadow: 1px 1px 2px #000;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    top: 65px !important;
    margin-left: 8vmin;
    text-shadow: 1px 1px 2px #000;
  }
`;

const BlogTitleHeading = styled.h1`
  font-family: unset;
  text-transform: none;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  font-size: 50px;
  margin-bottom: 0;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    font-size: 25px;
    width: 85vw;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 85vw;
    font-size: 40px;
  }
`;

const BlogDateText = styled.p`
  margin-bottom: 35px;
  margin-top: 10px;
  color: white;
  font-size: 18px;
`;

const BlogTitle = ({ blogTitle, blogDate }) => {
  return (
    <TitleAreaDiv>
      <BlogTitleHeading>{blogTitle}</BlogTitleHeading>
      <BlogDateText>{blogDate}</BlogDateText>
    </TitleAreaDiv>
  );
};

export default BlogTitle;
