import React from 'react';
import styled from 'styled-components';
import PortableText from '@sanity/block-content-to-react';
import { serializers } from '../Shared/utils/serializers';

const BlogArticleStyle = styled.article`
  width: 60vw;
  max-width: 650px;
  font-size: 18px;
  line-height: 1.4;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    width: 80vw;
    font-size: 16px;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 80vw;
  }
`;

const BlogArticleDiv = styled.div`
  margin-bottom: 35px;
`;

const BlogArticle = ({ blogBody }) => {
  return (
    <BlogArticleStyle>
      <BlogArticleDiv>
        <PortableText blocks={blogBody} serializers={serializers} />
      </BlogArticleDiv>
    </BlogArticleStyle>
  );
};

export default BlogArticle;
