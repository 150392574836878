import React from 'react';
import styled from 'styled-components';
import BlogNavigator from './BlogNavigator';
import { Link } from 'gatsby';
import BlogTags from './BlogTags';
import BlogArticle from './BlogArticle';

const BlogSectionStyle = styled.section`
  padding: 40px 8vmin 10vmin;
  color: #141618;
  background: #fff;
  position: relative;
  margin: 0;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    border-top: 3px solid orange;
    padding: 40px 8vmin 20vmin;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    border-top: 3px solid orange;
    padding: 40px 8vmin 10vmin;
  }
`;

const BlogSection = ({ blogEntry, allBlogEntries }) => {
  return (
    <BlogSectionStyle>
      {/* Blog Navigator */}
      {allBlogEntries.nodes.length > 0 && (
        <BlogNavigator allBlogEntries={allBlogEntries.nodes} />
      )}
      {/* Tags */}
      <BlogTags blogTags={blogEntry.blogTags} />
      {/* Article */}
      <BlogArticle blogBody={blogEntry._rawBlogBody} />
      <Link to='/blog'>See all blogs →</Link>
    </BlogSectionStyle>
  );
};

export default BlogSection;
