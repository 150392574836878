import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const BlogNavDiv = styled.div`
  position: sticky;
  top: 0;
  margin-top: -40px;
  float: right;
  z-index: 4000;
  width: 250px;
  padding: 50px 0;
  border-left: 1px solid #ccc;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    display: none;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    display: none;
  }
`;

const BlogNavHeading = styled.h4`
  color: #141618;
  padding: 0 0 0 20px;
  font-size: 20px;
`;

const BlogNavList = styled.ul`
  list-style: square;
  line-height: 1;
`;

const BlogNavListItem = styled.li`
  padding-bottom: 10px;
  color: #b5b3b3;
  font-size: 18px;
`;

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
  color: #b5b3b3;
`;

const BlogNavigator = ({ allBlogEntries }) => {
  return (
    <BlogNavDiv>
      <BlogNavHeading>More on this site</BlogNavHeading>
      <BlogNavList>
        {allBlogEntries.map((blogEntry) => {
          return (
            <BlogNavListItem key={blogEntry.id}>
              <GatsbyLinkStyled to={'/' + blogEntry.blogSlug.current}>
                {blogEntry.blogEntryTitle}
              </GatsbyLinkStyled>
            </BlogNavListItem>
          );
        })}
      </BlogNavList>
    </BlogNavDiv>
  );
};

export default BlogNavigator;
