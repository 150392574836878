import React from 'react';
import styled from 'styled-components';

const TagHeading = styled.h6`
  color: #fff;
  background: #222;
  border: 1px solid #222;
  text-transform: uppercase;
  font-weight: 500;
  font-style: normal;
  margin: 10px 10px 10px 0;
  display: inline-block;
  font-size: 13px;
  padding: 4px 20px;
  border-radius: 2px;

  @media screen and (max-width: 600px) {
    margin: 10px 5px 10px 0;
    display: inline-block;
    padding: 4px 10px;
  }
`;

const BlogTags = ({ blogTags }) => {
  return (
    <React.Fragment>
      {blogTags.map((tag, i) => {
        return (
          <TagHeading key={i}>
            <span>{tag}</span>
          </TagHeading>
        );
      })}
    </React.Fragment>
  );
};

export default BlogTags;
