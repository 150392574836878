import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../components/Shared/layoutWhite';
import BlogTitle from '../components/BlogTemplate/BlogTitle';
import BlogSection from '../components/BlogTemplate/BlogSection';
import { graphql } from 'gatsby';

const MainSectionStyle = styled.main`
  margin: 0;
  background: #fff;
  position: relative;
  width: 100vw;
  padding-top: 250px;
  padding-left: 9vmin;
  height: 100%;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    padding-left: 0;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding-left: 0;
  }
`;

const BackgroundImageDivStyle = {
  background: 'rgba(0,0,0,0) no-repeat scroll center center',
  position: 'absolute',
  left: '0',
  top: '0',
  width: '101vw',
  height: '400px',
  borderBottom: '5px solid orange',
  backgroundSize: 'cover !important',
};

const BlogTemplate = ({ data }) => {
  const allBlogEntries = data.allSanityBlogPost;
  const blogEntry = data.sanityBlogPost;
  const blogImage = getImage(blogEntry.blogHeadingImage.asset);
  return (
    <Layout>
      <MainSectionStyle>
        {/* Title Area */}
        <BlogTitle
          blogTitle={blogEntry.blogEntryTitle}
          blogDate={blogEntry.dateAndTme}
        />
        {/* Background Image */}
        <GatsbyImage
          image={blogImage}
          alt={blogEntry.blogHeadingImage.alt}
          style={BackgroundImageDivStyle}
        />
        {/* Blog Section */}
        <BlogSection blogEntry={blogEntry} allBlogEntries={allBlogEntries} />
      </MainSectionStyle>
    </Layout>
  );
};

export const singleBlogEntry = graphql`
  query singleBlogEntry($id: String!) {
    sanityBlogPost(_id: { eq: $id }) {
      _id
      blogEntryTitle
      blogHeadingImage {
        caption
        alt
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      dateAndTme(formatString: "DD MMMM, YYYY")
      _rawBlogBody
      blogTags
      blogSlug {
        current
      }
    }
    allSanityBlogPost(
      limit: 5
      sort: { fields: _createdAt, order: DESC }
      filter: { _id: { ne: $id } }
    ) {
      nodes {
        id
        blogEntryTitle
        blogSlug {
          current
        }
      }
    }
  }
`;

export default BlogTemplate;
